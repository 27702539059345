import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Metrics associated the activity of the contact center.`}</p>
    <p>{`The following tables list the data fields of Contact Center Activity listing panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number that the customer used to call the contact center. For example, `}<inlineCode parentName="td">{`351221234567`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tha handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual phone number that the customer used to call the contact center. Typically, mobile phone numbers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, one of the following:`}<ul><li>{`Inbound`}</li><li>{`Outbound`}</li><li>{`API`}</li><li>{`Calltracker`}</li><li>{`Click2Call`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Date`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the quarter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week of visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the weekday of the visit. One of the following:`}<ul><li>{`Sunday`}</li><li>{`Monday`}</li><li>{`Tuesday`}</li><li>{`Wednesday`}</li><li>{`Thursday`}</li><li>{`Friday`}</li><li>{`Saturday`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of the campaign.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      